import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="prestige-classes"></a><h2>Prestige Classes</h2>
    <a id="hierophant"></a><h3>HIEROPHANT</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
    <p className="initial"><a href="http://www.wizards.com/dnd/images/dmg35_gallery/DMG35_PG189_WEB.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
          "border": "0px solid",
          "width": "73px",
          "height": "75px"
        }} /></a><b>Hit Die</b>:
d8.</p>
    <a id="hierophant-requirements"></a><h6>Requirements</h6>
    <p className="initial">To qualify to become a hierophant, a character
must fulfill all the following criteria.</p>
    <p><b>Skills</b>: Knowledge (religion) 15 ranks.</p>
    <p><b>Feats</b>: Any metamagic feat.</p>
    <p><b>Spells</b>: Able to cast 7th-level divine spells.</p>
    <h6>Class Skills</h6>
    <p className="initial">The hierophant&#8217;s class skills (and the key
ability for each skill) are <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#concentration">Concentration</a>
(Con), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#craft">Craft</a>
(Int), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#diplomacy">Diplomacy</a> (Cha), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#heal">Heal</a>
(Wis), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#knowledge">Knowledge</a> (arcana/religion) (Int),
      <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#profession">Profession</a> (Wis), and <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#spellcraft">Spellcraft</a>
(Int). </p>
    <p><b>Skill Points at Each Level</b>: 2 + Int modifier.</p>
    <a id="table-the-hierophant"></a><p><b>Table: The Hierophant</b></p>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr valign="bottom">
          <th style={{
            "width": "8%"
          }}>Level</th>
          <th style={{
            "width": "15%"
          }}>Base
Attack Bonus</th>
          <th style={{
            "width": "8%"
          }}>Fort
Save</th>
          <th style={{
            "width": "8%"
          }}>Ref
Save</th>
          <th style={{
            "width": "8%"
          }}>Will
Save</th>
          <th>Special</th>
        </tr>
        <tr className="odd-row">
          <td>1st</td>
          <td>+0</td>
          <td>+2</td>
          <td>+0</td>
          <td>+2</td>
          <td>Special ability</td>
        </tr>
        <tr>
          <td>2nd</td>
          <td>+1</td>
          <td>+3</td>
          <td>+0</td>
          <td>+3</td>
          <td>Special ability</td>
        </tr>
        <tr className="odd-row">
          <td>3rd</td>
          <td>+1</td>
          <td>+3</td>
          <td>+1</td>
          <td>+3</td>
          <td>Special ability</td>
        </tr>
        <tr>
          <td>4th</td>
          <td>+2</td>
          <td>+4</td>
          <td>+1</td>
          <td>+4</td>
          <td>Special ability</td>
        </tr>
        <tr className="odd-row">
          <td>5th</td>
          <td>+2</td>
          <td>+4</td>
          <td>+1</td>
          <td>+4</td>
          <td>Special ability</td>
        </tr>
      </tbody>
    </table>
    <a id="hierophant-class-features"></a><h6>Class Features</h6>
    <p className="initial">All the following are Class Features of the
hierophant prestige class.</p>
    <p><b>Weapon and Armor Proficiency</b>: Hierophants gain no
proficiency with any weapon or armor.</p>
    <p><b>Spells and Caster Level</b>: Levels in the hierophant
prestige class, even though they do not advance spell progression in
the character&#8217;s base class, still stack with the character&#8217;s base
spellcasting levels to determine caster level.</p>
    <p><b>Special Ability</b>: Every level, a hierophant gains a
special ability of his choice from among the following.</p>
    <a id="hierophant-blast-infidel"></a><p><i>Blast Infidel (Su)</i>: A hierophant can use negative
energy spells to their maximum effect on creatures with an alignment
opposed to the hierophant. (See the table below for a list of which
alignments are opposed to each alignment.) Any spell with a description
that involves inflicting or channeling negative energy cast on a
creature of the opposed alignment works as if under the effect of a
Maximize Spell feat (without using a higher-level spell slot). Undead
affected by this ability heal the maximized amount of damage.</p>
    <a id="table-blast-infidel-opposed-alignments"></a><table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "50%"
    }}>
      <tbody>
        <tr>
          <th>Hierophant Alignment</th>
          <th>Opposed Alignment</th>
        </tr>
        <tr className="odd-row">
          <td>Lawful good</td>
          <td>Chaotic evil</td>
        </tr>
        <tr>
          <td>Neutral good</td>
          <td>Neutral evil</td>
        </tr>
        <tr className="odd-row">
          <td>Chaotic good</td>
          <td>Lawful evil</td>
        </tr>
        <tr>
          <td>Lawful neutral</td>
          <td>Chaotic neutral</td>
        </tr>
        <tr className="odd-row">
          <td>Neutral</td>
          <td>Lawful good, chaotic good, lawful evil, chaotic evil<sup>*</sup></td>
        </tr>
        <tr>
          <td>Chaotic neutral</td>
          <td>Lawful neutral</td>
        </tr>
        <tr className="odd-row">
          <td>Lawful evil </td>
          <td>Chaotic good</td>
        </tr>
        <tr>
          <td>Neutral evil</td>
          <td>Neutral good</td>
        </tr>
        <tr className="odd-row">
          <td>Chaotic evil</td>
          <td>Lawful good</td>
        </tr>
        <tr>
          <td colSpan="2">* <i>A neutral hierophant chooses one of
these alignments to be the one that he opposes, for the purposes of
this special ability.</i></td>
        </tr>
      </tbody>
    </table>
    <a id="hierophant-divine-reach"></a><p><i>Divine Reach (Su)</i>: A hierophant with this ability can
use touch spells on targets up to 30 feet away. If the spell requires a
melee touch attack, the hierophant must make a ranged touch attack
instead. Divine reach can be selected a second time as a special
ability, in which case the range increases to 60 feet.</p>
    <a id="hierophant-faith-healing"></a><p><i>Faith Healing (Su)</i>: A hierophant can use healing spells
to their maximum effect on creatures of the same alignment as the
hierophant (including the hierophant himself ). Any spell with the
healing descriptor cast on such creatures works as if under the effects
of a Maximize Spell feat (without using a higher-level spell slot).</p>
    <a id="hierophant-gift-of-the-divine"></a><p><i>Gift of the Divine (Su)</i>: Available only to hierophants
with cleric levels, this ability allows a hierophant to transfer one or
more uses of his turn undead ability to a willing creature.
(Hierophants who rebuke undead transfer uses of rebuke undead instead.)
The transfer lasts anywhere from 24 hours to one week (chosen at the
time of transfer), and while the transfer is in effect, the number of
turning attempts per day allowed to the hierophant is reduced by the
number transferred. The recipient turns undead as a cleric of the
hierophant&#8217;s cleric level but uses her own Charisma modifier.</p>
    <a id="hierophant-mastery-of-energy"></a><p><i>Mastery of Energy (Su)</i>: Available only to hierophants
with cleric levels, this ability allows a hierophant to channel
positive or negative energy much more effectively, increasing his
ability to affect undead. Add a +4 bonus to the hierophant&#8217;s turning
checks and turning damage rolls. This ability only affects undead, even
if the hierophant can turn other creatures, such as with a granted
power of a domain.</p>
    <p><i>Metamagic Feat</i>: A hierophant can choose a metamagic
feat in place of one of the special abilities described here if desired.</p>
    <a id="hierophant-power-of-nature"></a><p><i>Power of Nature (Su)</i>: Available only to hierophants
with druid levels, this ability allows a hierophant to temporarily
transfer one or more of his druid Class Features to a willing creature.
The transfer lasts anywhere from 24 hours to one week (chosen at the
time of transfer), and while the transfer is in effect, the hierophant
cannot use the transferred power. He can transfer any of his druid
powers except spellcasting and the ability to have an animal companion.</p>
    <p>The druid&#8217;s wild shape ability can be partially or completely
transferred. The heirophant choses how many uses of wild shape per day
to give to transfer and retains the rest of the uses for himself. If
the hierophant can assume the form of Tiny or Huge animals, the
recipient can as well.</p>
    <p>As with the imbue with spell ability spell, the hierophant
remains responsible to his deity for any use to which the recipient
puts the transferred abilities.</p>
    <a id="hierophant-spell-power"></a><p><i>Spell Power</i>: This special ability increases a
hierophant&#8217;s effective caster level by 1 for purposes of determining
level-dependent spell variables and for caster level checks. This
ability can be selected more than once, and changes to effective caster
level are cumulative.</p>
    <p><i>Spell-Like Ability</i>: A hierophant who selects this
special ability can use one of his divine spell slots to permanently
prepare one of his divine spells as a spell-like ability that can be
used twice per day. The hierophant does not use any components when
casting the spell, although a spell that costs XP to cast still does
so, and a spell with a costly material component instead costs him 10
times that amount in XP.</p>
    <p>The spell normally uses a spell slot of the spell&#8217;s level (or
higher, if the hierophant chooses to permanently attach a metamagic
feat to the spell chosen). The hierophant can use an available
higher-level spell slot to use the spell-like ability more than once
per day. Allocating a slot three levels higher allows him to cast the
spell four times per day, and a slot six levels higher lets him cast it
six times per day. If selected more than one time as a special ability,
this ability can apply to the same spell (increasing the number of
times per day it can be used) or to a different spell.</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      